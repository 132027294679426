let http = axios.create({
  timeout: 6000,
});

axios.get('/config.json').then(config => {
  http.defaults.baseURL = config.data.service_url
})

// const cancelToken = axios.CancelToken;
// const source = cancelToken.source()

http.interceptors.request.use((request) => {
  if (request.url.indexOf('i18n/getLanguage') == -1) {
    let userinfo = localStorage.getItem('userinfo');
    let token = JSON.parse(userinfo).token.token;
    request.params = {
      token: token,
      ...request.params,
    };

    request.headers['token'] = token;
  }
  return request;
});

http.interceptors.response.use((response) => {
  if (response.data.success) {
    return response.data;
  } else {
    if (response.data.code === '387679') {
      location.href = '/#/loginMap?tokenOver=true';
      localStorage.removeItem('userinfo');
    }
  }
});
export default http;


